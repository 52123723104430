export const register = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("service-worker.js")
        .then((res) => {
          console.log("success: ", res.scope);
        })
        .catch((err) => {
          console.log("fail");
        });
    });
  }
};
