import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlayer } from "./playerAPI";
import { updateLoading } from "Main/mainSlice";

export const loadPlayer = createAsyncThunk(
  "playerSlice/fetchPlayer",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(updateLoading({ scope: "player", loading: true }));
    const res = await fetchPlayer(params).catch((err) => {
      thunkAPI.dispatch(updateLoading({ scope: "player", loading: false }));
    });
    thunkAPI.dispatch(updateLoading({ scope: "player", loading: false }));
    const { total, data } = res;
    return { total, data };
  }
);

const playerSlice = createSlice({
  name: "playerSlice",
  initialState: {
    player: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPlayer.fulfilled, (state, action) => {
      state.player = action.payload.data;
      state.total = action.payload.total;
    });
  },
});

export const selectPlayer = (state) => state.player;

export default playerSlice.reducer;
