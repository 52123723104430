import axiosClient from "utils";

const addGift = async (data) => {
  const url = `/v1/gifts`;
  return axiosClient.post(url, data);
};

const updateGift = async (id, data) => {
  const url = `/v1/gifts/${id}`;
  return axiosClient.put(url, data);
};

export { addGift, updateGift };
