import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchMessages } from "./messageAPI";

export const loadMessage = createAsyncThunk(
  "messageSlice/fetchMessages",
  async (params, thunkAPI) => {
    const res = await fetchMessages(params);
    const { total, data } = res;
    return { total, data };
  }
);

const messageSlice = createSlice({
  name: "messageSlice",
  initialState: {
    loading: false,
    messages: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadMessage.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload.data;
      state.total = action.payload.total;
    });
  },
});

export const selectMessage = (state) => state.messages;

export default messageSlice.reducer;
