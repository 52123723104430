import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import queryString from "query-string";

import {
  fetchMatch,
  fetchTypeMatch,
  fetchTeam,
  createMatch,
  updateMatch,
} from "./matchAPI";
import { updateLoading } from "Main/mainSlice";

export const loadMatch = createAsyncThunk(
  "matchSlice/fetchMatch",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(updateLoading({ scope: "match", loading: true }));
    const res = await fetchMatch(params).catch(() => {
      thunkAPI.dispatch(updateLoading({ scope: "match", loading: false }));
    });
    thunkAPI.dispatch(updateLoading({ scope: "match", loading: false }));
    return { data: res.data, total: res.total };
  }
);
export const loadTypeMatch = createAsyncThunk(
  "matchSlice/fetchTypeMatch",
  async (params, thunkAPI) => {
    const res = await fetchTypeMatch();
    return res.data;
  }
);
export const loadTeam = createAsyncThunk(
  "matchSlice/fetchTeam",
  async (params, thunkAPI) => {
    const res = await fetchTeam(params);
    return res.data;
  }
);
export const addMatch = createAsyncThunk(
  "matchSlice/createMatch",
  async (params, thunkAPI) => {
    const res = await createMatch(params);
    const { locationQuery } = thunkAPI.getState().main;
    const query = queryString.parse(locationQuery);

    if (query.round_id) {
      return await thunkAPI.dispatch(loadMatch(query));
    }

    return res;
  }
);
export const editMatch = createAsyncThunk(
  "matchSlice/updateMatch",
  async (params, thunkAPI) => {
    const res = await updateMatch(params.data, params.id);
    return res;
  }
);

export const matchSlide = createSlice({
  name: "matchSlice",
  initialState: {
    matchList: [],
    total: 0,

    loadingTypeMatch: false,
    loadingTeam: false,
    loadingMatchModal: false,
    typeMatch: [],
    teams: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMatch.fulfilled, (state, action) => {
      state.matchList = action.payload.data;
      state.total = action.payload.total;
    });

    builder.addCase(loadTypeMatch.pending, (state) => {
      state.loadingTypeMatch = true;
    });
    builder.addCase(loadTypeMatch.rejected, (state, action) => {
      state.loadingTypeMatch = false;
    });
    builder.addCase(loadTypeMatch.fulfilled, (state, action) => {
      state.loadingTypeMatch = false;
      state.typeMatch = action.payload;
    });

    builder.addCase(loadTeam.pending, (state) => {
      state.loadingTeam = true;
    });
    builder.addCase(loadTeam.rejected, (state, action) => {
      state.loadingTeam = false;
    });
    builder.addCase(loadTeam.fulfilled, (state, action) => {
      state.loadingTeam = false;
      state.teams = action.payload;
    });

    builder.addCase(addMatch.pending, (state) => {
      state.loadingMatchModal = true;
    });
    builder.addCase(addMatch.rejected, (state) => {
      state.loadingMatchModal = false;
    });
    builder.addCase(addMatch.fulfilled, (state, action) => {
      state.loadingMatchModal = false;
    });

    builder.addCase(editMatch.pending, (state) => {
      state.loadingMatchModal = true;
    });
    builder.addCase(editMatch.rejected, (state) => {
      state.loadingMatchModal = false;
    });
    builder.addCase(editMatch.fulfilled, (state, action) => {
      state.loadingMatchModal = false;
      const index = state.matchList.findIndex(
        (i) => i.id === action.payload.id
      );
      if (index !== -1) {
        state.matchList.splice(index, 1, action.payload);
      }
    });
  },
});

export const selectMatch = (state) => state.match;

export default matchSlide.reducer;
