import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import mainSlice from "Main/mainSlice";
import matchSlice from "features/Match/matchSlice";
import matchDetailSlice from "features/MatchDetail/matchDetailSlice";
import loginSlice from "features/Login/loginSlice";
import playerSlice from "features/Player/playerSlice";
import playerDetailSlice from "features/PlayerDetail/playerDetailSlice";
import matchResultSlice from "features/MatchResult/matchResultSlice";
import giftSlice from "features/Gift/giftSlice";
import dashboardSlice from "features/Dashboard/dashboardSlice";
import messageSlice from "features/Message/messageSlice";

export const store = configureStore({
  reducer: {
    main: mainSlice,
    match: matchSlice,
    matchDetail: matchDetailSlice,
    login: loginSlice,
    player: playerSlice,
    playerDetail: playerDetailSlice,
    matchResult: matchResultSlice,
    gift: giftSlice,
    dashboard: dashboardSlice,
    messages: messageSlice,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        "matchSlice/fetchMatch/pending",
        "matchSlice/fetchMatch/fulfilled",
        "matchSlice/createMatch/fulfilled",
      ],
      ignoredActionPaths: ["meta.arg", "payload.timestamp"],
    },
  }),
});
