import { Spin } from "antd";

import "./loader.less";

export default function Loader() {
  return (
    <div className="loader-wrapper">
      <Spin tip="Loading..." />
    </div>
  );
}
