import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchTypeMatchDetail,
  updatePoint,
  updateRoundGift,
} from "./matchResultAPI";

export const loadTypeMatchDetail = createAsyncThunk(
  "matchResultSlice/fetchTypeMatchDetail",
  async (params, thunkAPI) => {
    const res = await fetchTypeMatchDetail(params);
    return res;
  }
);
export const editPoint = createAsyncThunk(
  "matchResultSlice/updatePoint",
  async (params, thunkAPI) => {
    const res = await updatePoint(params.id, params.data);
    return res;
  }
);
export const editRoundGift = createAsyncThunk(
  "matchResultSlice/updateRoundGift",
  async (params, thunkAPI) => {
    const res = await updateRoundGift(params.id, params.data);
    return res;
  }
);

const matchResultSlice = createSlice({
  name: "matchResultSlice",
  initialState: {
    typeMatchDetail: {},
    loadingTypeMatchDetail: false,
  },
  reducers: {
    updateMatchResult: (state, action) => {
      state.typeMatchDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTypeMatchDetail.pending, (state) => {
      state.loadingTypeMatchDetail = true;
    });
    builder.addCase(loadTypeMatchDetail.rejected, (state) => {
      state.loadingTypeMatchDetail = false;
    });
    builder.addCase(loadTypeMatchDetail.fulfilled, (state, action) => {
      state.loadingTypeMatchDetail = false;
      state.typeMatchDetail = action.payload;
    });
  },
});

export const selectMatchResult = (state) => state.matchResult;
export const { updateMatchResult } = matchResultSlice.actions;

export default matchResultSlice.reducer;
