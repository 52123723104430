import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import store from "store";
import { useDispatch } from "react-redux";

import { updateUserInfo } from "Main/mainSlice";

const PrivateRoute = ({ component, path, exact }) => {
  const dispatch = useDispatch();
  const isLogin = store.get("auth") ? true : false;

  useEffect(() => {
    const auth = store.get("auth");
    if (auth) {
      dispatch(updateUserInfo(auth));
    }
  }, [dispatch]);

  return isLogin ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};
export default PrivateRoute;
