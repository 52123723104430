import { createSlice } from "@reduxjs/toolkit";

const mainSlice = createSlice({
  name: "mainSlice",
  initialState: {
    locationPathname: undefined,
    locationQuery: undefined,
    breadcrumb: "",
    userInfo: {},
  },
  reducers: {
    updateLocation: (state, action) => {
      state.locationPathname = action.payload.pathName;
      state.locationQuery = action.payload.query;
    },
    updateBreadcrumb: (state, action) => {
      state.breadcrumb = action.payload;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    updateLoading: (state, action) => {
      state[`${action.payload.scope}Loading`] = action.payload.loading;
    },
  },
});

export const {
  updateLocation,
  updateBreadcrumb,
  updateUserInfo,
  updateLoading,
} = mainSlice.actions;
export const selectMain = (state) => state.main;

export default mainSlice.reducer;
