import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchStat } from "./dashboardAPI";

export const loadStat = createAsyncThunk(
  "dashboardSlice/fetchStat",
  async (params, thunkAPI) => {
    const res = await fetchStat(params);
    return res;
  }
);

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: {
    loading: false,
    top10Member: [],
    top10Match: [],
    totalMember: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadStat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadStat.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadStat.fulfilled, (state, action) => {
      state.loading = false;
      state.top10Member =
        action.payload.top_ten_member?.data.length > 0
          ? action.payload.top_ten_member?.data.reduce((result, cur) => {
              let obj = {};
              obj.name = cur.member_info?.member_crm_info?.name;
              obj.total = cur.total_match_member_predict;
              result.push(obj);
              return result;
            }, [])
          : [];

      state.top10Match =
        action.payload?.top_ten_match?.data.length > 0
          ? action.payload.top_ten_match?.data.reduce((result, cur) => {
              let obj = {};
              obj.name = `${cur.match_id}+${cur.match_info?.home_team_info?.name} - ${cur.match_info?.away_team_info?.name}`;
              obj.total = cur.total_member_predict;
              result.push(obj);
              return result;
            }, [])
          : [];

      state.totalMember =
        action.payload?.member_predict_match?.data.length > 0
          ? action.payload?.member_predict_match?.data.reduce((result, cur) => {
              let obj = {};
              if (
                cur.match_info?.home_team_info?.name &&
                cur.match_info?.away_team_info?.name
              ) {
                obj.name = `${cur.match_id}+${cur.match_info?.home_team_info?.name} - ${cur.match_info?.away_team_info?.name}`;
                obj.total = cur.total_member_predict;
                result.push(obj);
              }
              return result;
            }, [])
          : [];
    });
  },
});

export const selectDashboard = (state) => state.dashboard;
export default dashboardSlice.reducer;
