import { Dropdown, Menu, Avatar } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import store from "store";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectMain } from "Main/mainSlice";
import { redirectUrl } from "utils";

export default function HeaderDropdown() {
  const history = useHistory();
  const main = useSelector(selectMain);

  function onClick() {
    store.remove("auth");
    const { locationPathname, locationQuery } = main;
    const redirect = redirectUrl(locationPathname, locationQuery);
    history.push(`/login?redirectUrl=${encodeURIComponent(redirect.join(""))}`);
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={onClick} icon={<LogoutOutlined />}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter" arrow>
      <div>
        <span style={{ marginRight: "6px" }}>
          {main.userInfo?.name || main.userInfo?.user_name}
        </span>
        <Avatar shape="square" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
}
