import axiosClient from "utils";

const fetchMatch = async (params) => {
  const url = `/v1/matches`;
  return axiosClient.get(url, { params });
};

const fetchTypeMatch = async () => {
  const url = `/v1/rounds`;
  return axiosClient.get(url);
};

const fetchTeam = async (params) => {
  const url = `/v1/teams`;
  return axiosClient.get(url, { params });
};

const createMatch = async (data) => {
  const url = `/v1/matches`;
  return axiosClient.post(url, data);
};

const updateMatch = async (data, id) => {
  const url = `/v1/matches/${id}`;
  return axiosClient.put(url, data);
};

export { fetchMatch, fetchTypeMatch, fetchTeam, createMatch, updateMatch };
