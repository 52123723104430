import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchPlayerDetail, fetchHistoryList } from "./playerDetailAPI";

export const loadPlayerDetail = createAsyncThunk(
  "playerDetailSlice/fetchPlayerDetail",
  async (params, thunkAPI) => {
    const res = await fetchPlayerDetail(params);
    return res;
  }
);
export const loadHistoryList = createAsyncThunk(
  "playerDetailSlice/fetchHistoryList",
  async (params, thunkAPI) => {
    const res = await fetchHistoryList(params);
    const { data, total } = res;
    return { data, total };
  }
);

const playerDetailSlice = createSlice({
  name: "playerDetailSlice",
  initialState: {
    detail: {},
    history: [],
    totalHistory: 0,
    loading: false,
    loadingHistory: false,
  },
  reducers: {
    clearHistory: (state, action) => {
      state.history = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPlayerDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadPlayerDetail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(loadPlayerDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.detail = action.payload;
    });

    builder.addCase(loadHistoryList.pending, (state, action) => {
      state.loadingHistory = true;
    });
    builder.addCase(loadHistoryList.rejected, (state, action) => {
      state.loadingHistory = false;
    });
    builder.addCase(loadHistoryList.fulfilled, (state, action) => {
      state.loadingHistory = false;
      state.history = action.payload.data;
      state.totalHistory = action.payload.total;
    });
  },
});

export const selectDetail = (state) => state.playerDetail;
export const { clearHistory } = playerDetailSlice.actions;

export default playerDetailSlice.reducer;
