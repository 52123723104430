import axiosClient from "utils";

async function fetchPlayerDetail(id) {
  const url = `/v1/members/${id}`;
  return axiosClient.get(url);
}

async function fetchHistoryList(params) {
  const url = `/v1/predicts`;
  return axiosClient.get(url, { params });
}

export { fetchPlayerDetail, fetchHistoryList };
